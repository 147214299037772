import { default as React, Fragment } from "react";
import { Helmet } from "react-helmet";
import "~/styles/zelle.css";

const ZellePage = () => {
  return (
    <Fragment>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap" rel="stylesheet" />
        <title>Send and receive money with Zelle®</title>
      </Helmet>
      <div className="lp_page_wrapper">
        <main className="lp_content_wrapper">
            <section className="heading">
                <div className="heading_backdrop">
                    <div className="heading_backdrop_overlay">
                        <div className="relative">
                            <h1>Send and receive<br /> money with Zelle<sup>&reg;</sup></h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="logos">
                <div className="contnr">

                    <img className="logo"
                        src="https://images.printable.com/imagelibrary/Seller/22712/p1_cbdc97ed-0086-4e2d-9d87-669a590c1ced/images/5650696/src/Zelle_Standard_Logo_Lockup_Generator_01242023_43a4ae84-3323-4f53-93e1-a7e6d16bf1f0.jpg"
                        alt="Kirkwood Bank & Trust together with Zelle®" width="415" />
                </div>
            </section>
            <section className="heading_bottom">
                <div className="contnr">
                    <div className="heading_content p">
                        <div className="heading_login showF92"><a
                                href="https://web13.secureinternetbank.com/PBI_PBI1151/Login/091310592/1"
                                target="_blank" rel="noreferrer">Log in and enroll</a></div>
                        <p style={{margin: "80px 0 0" }}>We have partnered with Zelle<sup>&reg;</sup> to bring you a
                            <strong> fast and easy way to send and receive money</strong> with friends, family and people
                            you know. With Zelle<sup>&reg;</sup>, you can <strong>send money directly from your account
                                to enrolled recipients in minutes</strong>, all from the convenience of online banking
                            or our mobile app.</p>
                        <p style={{marginTop: "30px"}}><strong>You don’t need to download anything new! Just use your
                                email address or U.S. mobile number to get started: </strong> log in to your online
                            banking account and enroll in Bill Pay. Once your account is verified, return to Bill Pay
                            and select the "Send Money With Zelle<sup>&reg;</sup>" tab.</p>
                    </div>
                    <div className="divider"></div>
                </div>
            </section>
            <section className="steps">
                <div className="contnr">
                    <h2 className="steps_headline">Easily start today using just your email address or U.S. mobile number:
                    </h2>
                    <div className="instruction">
                        <div className="instruction_num">
                            <h3>1</h3>
                        </div>
                        <h3>Log in to Bill Pay</h3>

                    </div>
                    <div className="instruction">
                        <div className="instruction_num">
                            <h3>2</h3>
                        </div>
                        <h3>Select “Send Money With Zelle<sup>&reg;</sup>”</h3>
                    </div>
                    <div className="instruction">
                        <div className="instruction_num">
                            <h3>3</h3>
                        </div>
                        <h3>Accept Terms and Conditions</h3>
                    </div>
                    <div className="instruction">
                        <div className="instruction_num">
                            <h3>4</h3>
                        </div>
                        <h3>Select your U.S. mobile number or email address and deposit account</h3>
                    </div>

                    <h3 className="instruction_close">That's it! You're ready to start sending and receiving money with
                        Zelle<sup>&reg;</sup>.</h3>
                    <div className="divider"></div>
                </div>
            </section>
            <section className="app">
                <div className="contnr">
                    <h2>Don't have our mobile app? <span style={{fontWeight: "600"}}>Download it for free:</span></h2>

                    <div className="row">
                        <div className="col app-logo Yes">
                            <a href="https://apps.apple.com/us/app/kirkwood-bank-trust-mobile/id932153369"
                                target="_blank" rel="noreferrer">
                                <img src="https://images.printable.com/imagelibrary/Seller/3346/FiservHTMLIcons_12282017134738_260/images/src/apple.png"
                                    alt="Download it on the App Store" />
                            </a>
                        </div>
                        <div className="col app-logo Yes">
                            <a href="https://play.google.com/store/apps/details?id=com.kirkwoodbandt.mobile"
                                target="_blank" rel="noreferrer">
                                <img src="https://images.printable.com/imagelibrary/Seller/3346/FiservHTMLIcons_12282017134738_260/images/src/google.png"
                                    alt="Get it on Google Play" />
                            </a>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>
            </section>
            <section className="tips showF92">
                <div className="contnr">
                    <h2>Follow these simple tips to ensure your money is sent safely:</h2>
                    <div className="row">
                        <div className="col">
                            <img src="https://images.printable.com/imagelibrary/Seller/3346/ZelleLandingPages-2023_01112023112821_176/images/lr/Zelle_LP_ICON_know.png"
                                alt="lightbulb icon" />
                            <h3>Know</h3>
                            <h4>Only use Zelle<sup>&reg;</sup> to send money to friends, family and other people you
                                trust.</h4>
                        </div>
                        <div className="col">
                            <img src="https://images.printable.com/imagelibrary/Seller/3346/ZelleLandingPages-2023_01112023112821_176/images/lr/Zelle_LP_ICON_verify.png"
                                alt="checkmark icon" />
                            <h3>Verify</h3>
                            <h4>Make sure your recipient's name, U.S. mobile phone number and email address are correct
                                before sending money.</h4>
                        </div>
                        <div className="col">
                            <img src="https://images.printable.com/imagelibrary/Seller/3346/ZelleLandingPages-2023_01112023112821_176/images/lr/Zelle_LP_ICON_aware.png"
                                alt="magnifying glass icon" />
                            <h3>Be Aware</h3>
                            <h4>If a payment situation feels off, it probably is. Trust your gut and investigate.</h4>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>
            </section>
            <section className="video showF92">
                <div className="contnr">
                    <h2>Watch the video to learn more about Zelle<sup>&reg;</sup>!</h2>
                    <div className="video_backdrop">
                        <a className="video_backdrop_overlay"
                            href="https://learnaboutmoneymovement.com/videos/ZelleHowTo.mp4" target="_blank" rel="noreferrer"><img
                                src="https://images.printable.com/imagelibrary/Seller/3346/ZelleLandingPages-2023_01112023112821_176/images/src/VideoThumb_ZelleHowTo.png"
                                alt="video placeholder" width="1140" /></a>
                    </div>
                </div>
            </section>
            <section className="faqs">
                <div className="contnr">
                    <div className="divider showF92"></div>
                    <div className="accordian-heading">
                        <h2>Frequently Asked Questions</h2>
                    </div>

                    <div className="col cssAccordion">
                        <div className="tabs">
                            <div className="tab">
                                <input type="radio" id="rd1" name="rd" />
                                <label className="tab-label" for="rd1">
                                    <h3>How do I enroll and use Zelle<sup>&reg;</sup>?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>You can send, request or receive money with Zelle<sup>&reg;</sup>.</p>
                                    <ul>
                                        <li>To start using Zelle<sup>&reg;</sup> at Kirkwood Bank & Trust, you must be
                                            enrolled in Bill Pay. If you are not already enrolled in Bill Pay, you can
                                            enroll by accessing our website and logging in to Online Banking. Locate the
                                            Bill Payment tab and follow the instructions to complete the Bill Pay
                                            enrollment steps. Once enrolled in Bill Pay, you can access “Send Money With
                                            Zelle<sup>&reg;</sup>” tab in Bill Pay to complete a brief one-time
                                            enrollment to tell us which email address or U.S. mobile number and deposit
                                            account you would like to use to send and receive money with
                                            Zelle<sup>&reg;</sup>.</li>
                                        <li>Note: Zelle<sup>&reg;</sup> enrollment is not currently available through
                                            the mobile app; however, you will be able to access Zelle<sup>&reg;</sup>
                                            with the mobile app once enrolled.</li>
                                        <li>To send money using Zelle<sup>&reg;</sup>, simply add a trusted recipient's
                                            email address or U.S. mobile phone number, enter the amount you'd like to
                                            send and an optional note, review, then hit "Send." In most cases, the money
                                            is available to your recipient in minutes if they are already enrolled with
                                            Zelle<sup>&reg;</sup>.</li>
                                        <li>To request money using Zelle<sup>&reg;</sup>, choose "Request," select the
                                            individual from whom you'd like to request money, enter the amount you'd
                                            like to request, include an optional note, review and hit "Request." If the
                                            person you are requesting money from is not yet enrolled with
                                            Zelle<sup>&reg;</sup>, you must use their email address to request money. If
                                            the person has enrolled their U.S. mobile number, then you can send the
                                            request using their mobile number.</li>
                                        <li>To receive money, just share your enrolled email address or U.S. mobile
                                            number with a friend and ask them to send you money with
                                            Zelle<sup>&reg;</sup>. If you have already enrolled with
                                            Zelle<sup>&reg;</sup>, you do not need to take any further action. The money
                                            will be sent directly into your account, typically within minutes.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd2" name="rd" />
                                <label className="tab-label" for="rd2">
                                    <h3>How do I receive money that someone sent me with Zelle<sup>&reg;</sup>?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>If you have already enrolled with Zelle<sup>&reg;</sup>, you do not need to take
                                        any further action. The money will be sent directly into your bank account and
                                        will be available typically within minutes.
                                    </p>
                                    <p>If you have not yet enrolled with Zelle<sup>&reg;</sup>, follow these steps:</p>
                                    <ol>
                                        <li>Click on the link provided in the payment notification you received via
                                            email or text message.
                                        </li>
                                        <li>Select Kirkwood Bank & Trust.</li>
                                        <li>Follow the instructions provided on the page to enroll and receive your
                                            payment.
                                            <br />
                                            <i>Pay attention to the email address or U.S. mobile number where you
                                                received the payment notification &mdash; you should enroll with
                                                Zelle<sup>&reg;</sup>using that email address or U.S. mobile number
                                                where you received the notification to ensure you receive your
                                                money.</i>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd3" name="rd" />
                                <label className="tab-label" for="rd3">
                                    <h3>What if I get an error message when I try to enroll an email address or U.S.
                                        mobile number?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>Your email address or U.S. mobile phone number may already be enrolled with
                                        Zelle<sup>&reg;</sup> at another bank or credit union. Call our <span
                                            className="FItypeFDIC"></span> support team 800.492.4955 and ask them to move
                                        your email address or U.S. mobile phone number to Kirkwood Bank & Trust so you
                                        can use it for Zelle<sup>&reg;</sup>.</p>
                                    <p>Once <span className="FItypeFDIC"></span> support moves your email address or U.S.
                                        mobile phone number, it will be connected to your Kirkwood Bank & Trust account
                                        so you can start sending and receiving money with Zelle<sup>&reg;</sup> through
                                        the Kirkwood Bank & Trust mobile banking app and online banking. Please call
                                        Kirkwood Bank & Trust's <span className="FItypeFDIC"></span> support toll-free at
                                        800.492.4955 for help.</p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd4" name="rd" />
                                <label className="tab-label" for="rd4">
                                    <h3>Is my information secure?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>Keeping your money and information safe is a top priority for Kirkwood Bank &
                                        Trust. When you use Zelle<sup>&reg;</sup> within our mobile app or online
                                        banking, your information is protected with the same technology we use to keep
                                        your bank account safe.</p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd5" name="rd" />
                                <label className="tab-label" for="rd5">
                                    <h3>Who can I send money to with Zelle<sup>&reg;</sup>?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>Zelle<sup>&reg;</sup> is a great way to send money to family, friends and people
                                        you are familiar with such as your personal trainer, babysitter or
                                        neighbor.<sup><a className="foot-link" href="#foot1">1</a></sup></p>
                                    <p>Since money is sent directly from your bank account to another person's bank
                                        account within minutes,<sup><a className="foot-link" href="#foot1"> 1</a></sup>
                                        Zelle<sup>&reg;</sup> should only be used to send money to friends, family and
                                        others you trust.</p>
                                    <p>If you don't know the person or aren't sure you will get what you paid for (for
                                        example, items bought from an online bidding or sales site), you should not use
                                        Zelle<sup>&reg;</sup>. These transactions are potentially high risk (just like
                                        sending cash to a person you don't know is high risk).</p>
                                    <p>Neither Kirkwood Bank & Trust nor Zelle<sup>&reg;</sup> offers a protection
                                        program for any authorized payments made with Zelle<sup>&reg;</sup> &ndash; for
                                        example, if you do not receive the item you paid for or the item is not as
                                        described or as you expected.</p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd6" name="rd" />
                                <label className="tab-label" for="rd6">
                                    <h3>What if I want to send money to someone whose financial institution doesn't
                                        offer
                                        Zelle<sup>&reg;</sup>?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>You can find a full list of participating banks and credit unions live with
                                        Zelle<sup>&reg;</sup> <a
                                            href="https://www.zellepay.com/participating-banks-and-credit-unions">here</a>
                                    </p>
                                    <p>If your recipient's financial institution isn't on the list, don't worry! The
                                        list of participating financial institutions is always growing, and your
                                        recipient can still use Zelle<sup>&reg;</sup> by downloading the
                                        Zelle<sup>&reg;</sup> app for Android and iOS.</p>
                                    <p>To enroll with the Zelle<sup>&reg;</sup> app, your recipient will enter their
                                        basic contact information, an email address and U.S. mobile number and a
                                        Visa<sup>&reg;</sup>or Mastercard<sup>&reg;</sup> debit card with a U.S. based
                                        account (does not include U.S. territories). Zelle<sup>&reg;</sup> does not
                                        accept debit cards associated with international deposit accounts or any credit
                                        cards.</p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd7" name="rd" />
                                <label className="tab-label" for="rd7">
                                    <h3>Can I use Zelle<sup>&reg;</sup> internationally?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>To use Zelle<sup>&reg;</sup>, the sender and recipient's bank accounts must be
                                        based in the U.S. </p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd8" name="rd" />
                                <label className="tab-label" for="rd8">
                                    <h3>Can I cancel a payment?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>You can only cancel a payment if the person you sent money to hasn't yet enrolled
                                        with Zelle<sup>&reg;</sup>. To check whether the payment is still pending
                                        because the recipient hasn't yet enrolled, you can go to your activity page,
                                        choose the payment you want to cancel and then select "Cancel This Payment."</p>
                                    <p>If the person you sent money to has already enrolled with Zelle<sup>&reg;</sup>,
                                        the money is sent directly to their bank account and cannot be canceled. This is
                                        why it's important to only send money to people you know and trust, and always
                                        ensure you've used the correct email address or U.S. mobile number when sending
                                        money. </p>
                                    <p>If you sent money to the wrong person, please immediately call <span
                                            className="FItypeFDIC"></span> services at 800.492.4955 so we can help you.</p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd9" name="rd" />
                                <label className="tab-label" for="rd9">
                                    <h3>What are scheduled and recurring payments?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>We offer you the flexibility to schedule payments to be sent at a later date if
                                        you like, or even set up recurring payments for things like regularly sending
                                        money to your college student.</p>
                                    <p>Scheduled or recurring payments sent directly to your recipient's account number
                                        (instead of an email address or mobile number) are made available by Kirkwood
                                        Bank & Trust but are a separate service from Zelle<sup>&reg;</sup> and can take
                                        one to three business days to process. You can cancel a payment that is
                                        scheduled in advance if the money has not already been deducted from your
                                        account.
                                    </p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd10" name="rd" />
                                <label className="tab-label" for="rd10">
                                    <h3>Are there any fees to send money using Zelle<sup>&reg;</sup>?</h3>
                                </label>
                                <div className="tab-content">
                                    <p className="showF92">No, Kirkwood Bank & Trust does not charge any fees to use
                                        Zelle<sup>&reg;</sup>. Your mobile carrier's messaging and data rates may apply.
                                    </p>
                                    <p className="hideF92">Kirkwood Bank & Trust does not charge fees to send or receive
                                        money with Zelle<sup>&reg;</sup>, but there is a fee to cancel a
                                        Zelle<sup>&reg;</sup> transaction that is still pending because the recipient
                                        hasn't yet enrolled. If the recipient doesn't enroll with Zelle<sup>&reg;</sup>
                                        within 14 days, the payment will naturally expire, the funds will be returned to
                                        your account and no fees will be assessed.</p>
                                    <p className="hideF92">Your mobile carrier's messaging and data rates may apply.</p>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="radio" id="rd11" name="rd" />
                                <label className="tab-label" for="rd11">
                                    <h3 style={{maxWidth: "80%"}}>Are there any limits for sending and receiving money
                                        with Zelle<sup>&reg;</sup>?</h3>
                                </label>
                                <div className="tab-content">
                                    <p>The amount of money you can send, as well as the frequency, is set by each
                                        participating financial institution. To determine Kirkwood Bank & Trust send
                                        limits, call our <span className="FItypeFDIC"></span> service at 800.492.4955.</p>
                                    <p>There are no limits to the amount of money you can receive with
                                        Zelle<sup>&reg;</sup>. However, remember that the person sending you money will
                                        most likely have limits set by their own financial institution on the amount of
                                        money they can send you.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="disclaimer">
                <div className="contnr">
                    <div className="footer_login"><a
                            href="https://web13.secureinternetbank.com/PBI_PBI1151/Login/091310592/1" target="_blank" rel="noreferrer">Log
                            in and enroll</a></div>
                    <div className="footnotes">
                        <p id="foot1"><sup>1 </sup>U.S. checking or savings account required to use
                            Zelle<sup>&reg;</sup>. Transactions between enrolled users typically occur in minutes. To
                            send or receive money with a small business, both parties must be enrolled with
                            Zelle<sup>&reg;</sup> directly through their financial institutions online or mobile
                            banking experience.</p>
                        <p className="YesYes">Data charges may apply. Check with your mobile phone carrier for details.
                            <span className="Yes">App Store is a service mark of Apple Inc. Apple and the Apple logo are
                                trademarks of Apple Inc, registered in the U.S. and other countries. </span><span
                                className="Yes">Android, Google Play and the Google Play logo are trademarks of Google Inc.
                            </span></p>
                        <p>Copyright &copy; 2023 Kirkwood Bank &amp; Trust. All rights reserved. Zelle<sup>&reg;</sup> and
                            the Zelle<sup>&reg;</sup> related marks are wholly owned by Early Warning Services, LLC and
                            are used herein under license.</p>
                        <p>
                            Kirkwood Bank &amp; Trust<br />
                            <br />
                            , <br />
                            800.492.4955<br />
                        </p>
                        <div className="disclaimer-logos">
                            <p><span className="disclaimer-logos FDIC EQL">
                                    <img className="fdic-block"
                                        src="/member-fdic.png"
                                        alt="Member FDIC." />
                                    <img className="ncua-block"
                                        src="https://images.printable.com/imagelibrary/Seller/3346/FiservLogos_08012017163253_397/images/src/NCUA-LPlogo.png"
                                        alt="Member NCUA" />
                                    <img className="eql-block"
                                        src="https://images.printable.com/imagelibrary/Seller/3346/FiservLogos_08012017163253_397/images/src/EQL-LPlogo.png"
                                        alt="Equal Housing Lender" />
                                </span></p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    </Fragment>
  )
}

export default ZellePage;